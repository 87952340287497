type EnvironmentSpecificConfig = {
  API_URL: string;
  API_PATH_PREFIX: string;
};

const dev: EnvironmentSpecificConfig = {
  API_URL: 'http://localhost:8088',
  API_PATH_PREFIX: 'dev',
};
const test: EnvironmentSpecificConfig = {
  API_URL: 'https://xo301o9tae.execute-api.eu-central-1.amazonaws.com',
  API_PATH_PREFIX: 'test',
};

const prod: EnvironmentSpecificConfig = {
  API_URL: 'https://y5dfotnsi3.execute-api.eu-central-1.amazonaws.com',
  API_PATH_PREFIX: 'prod',
};

const env = process.env.REACT_APP_STAGE || 'dev';

const config = env === 'test' ? test : env === 'prod' ? prod : dev;

const mergedConfig = {
  ...config,
  STANDALONE: env === 'dev',
};

export const MICROFRONTEND_ID = 'emailNotifications';

export default mergedConfig;
